@use 'bootstrap';

.icon-size {
  @each $index, $size in bootstrap.$spacers {
    &-#{$index} {
      width: $size;
      height: $size;
    }
  }
}

;@import "sass-embedded-legacy-load-done:975";