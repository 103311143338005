@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.tooltip {
  position: relative;
  opacity: 1 !important;

  :global {
    .tooltip-inner {
      text-align: start;
      border-radius: 4px;
      color: variables.$foreground-high;
      background-color: variables.$surface-1;
      width: functions.get-rem(350px);
      max-width: functions.get-rem(350px);
      padding: functions.get-rem(16px);
      border: 1px solid variables.$stroke-high;
      box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.25);
    }

    .tooltip-arrow:before {
      bottom: 0 !important;
      //border-top-color: variables.$surface-1 !important;
      //border-bottom-color: variables.$surface-1 !important;
    }
  }

  &.modal {
    :global {
      .tooltip-inner {
        text-align: start;
        border-radius: 4px;
        color: variables.$foreground-high;
        padding: functions.get-rem(16px);
        border: 1px solid variables.$stroke-high;
        background-color: variables.$surface-1;
        width: functions.get-rem(350px) !important;
        max-width: functions.get-rem(350px) !important;
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.25);
      }

      .tooltip-arrow:before {
        display: none;
      }
    }
  }

  &.custom-overlay {
    :global {
      .tooltip-inner {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .icon {
    cursor: pointer;
    position: absolute;
    top: functions.get-rem(18px);
    right: functions.get-rem(16px);
  }
}

.main-container {
  cursor: help;
  display: inline-block;
}

.title {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.close-btn {
  top: 8px;
  right: 8px;
}
