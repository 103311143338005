@use "scss/variables";
@use "scss/functions";
@use "mixins";

// Headlines

@mixin head($size) {
  @include mixins.canela-text;
  margin: 0;
  font-size: functions.get-rem(map-get(variables.$head, $size));
  line-height: map-get(variables.$line-height, $size);
}

@each $key in map-keys(variables.$head) {
  .head-#{$key} {
    @include head($key);
  }
}

// Paragraphs

@mixin paragraph($size) {
  margin: 0;
  font-size: functions.get-rem(map-get(variables.$paragraph, $size));
  line-height: variables.$line-height-lg;
}

@each $key in map-keys(variables.$paragraph) {
  .paragraph-#{$key} {
    @include paragraph($key);
  }
}

// UI
// The style in these can be overwritten for each element to achieve bold, italic and so on using style or html tag

@mixin ui($size) {
  font-size: functions.get-rem(map-get(variables.$ui, $size));
  line-height: variables.$line-height-base;
}

@mixin ui-bold($size) {
  @include ui($size);

  font-weight: variables.$font-weight-bold;
}

@mixin ui-medium($size) {
  @include ui($size);

  font-weight: variables.$font-weight-medium;
}

@mixin ui-italic($size) {
  @include ui($size);

  font-style: italic;
}

@mixin ui-underline($size) {
  @include ui($size);

  text-decoration: underline;
}

@each $key in map-keys(variables.$ui) {
  .ui-#{$key} {
    @include ui($key);
  }

  .ui-bold-#{$key} {
    @include ui-bold($key);
  }

  .ui-medium-#{$key} {
    @include ui-medium($key);
  }

  .ui-italic-#{$key} {
    @include ui-italic($key);
  }

  .ui-underline-#{$key} {
    @include ui-underline($key);
  }
}

@each $name, $value in $grid-breakpoints {
  @media all and (min-width: $value) {
    @each $key, $value in variables.$ui {
      .ui-#{$name}-#{$key} {
        font-size: functions.get-rem($value);
        line-height: variables.$line-height-base;
      }
    }
  }
}

// Colors

.text-low {
  color: variables.$foreground-low;
}

.text-medium {
  color: variables.$foreground-medium;
}

.text-disabled {
  color: variables.$foreground-disabled;
}



;@import "sass-embedded-legacy-load-done:976";