@use "scss/functions";
@use "scss/variables";

.single {
  flex: 0 0 32.5%;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  height: functions.get-rem(48px) !important;
  background-color: variables.$additional-1-200;

  svg {
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);

    path {
      stroke: variables.$foreground-low;
    }
  }
}

.tab-switcher {
  display: flex;
  padding: 0 2px;
  align-items: center;
  position: relative;
  border-radius: 8px;
  height: functions.get-rem(36px);
  background-color: variables.$element-3;

  .tab-item {
    z-index: 2;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(32px);
    height: functions.get-rem(32px);

    svg {
      color: variables.$additional-6-500;
    }

    &.active {
      svg {
        color: variables.$primary-1;
      }
    }
  }

  .active-tab-item {
    top: 2px;
    left: 2px;
    position: absolute;
    border-radius: 8px;
    transition: all 0.4s ease-out;
    width: functions.get-rem(32px);
    height: functions.get-rem(32px);
    background-color: variables.$surface-1;

    &.right {
      left: 49%;
    }
  }
}

.close-btn {
  top: 8px;
  right: 8px;
}

.share-class {
  width: 100% !important;
  box-shadow: none !important;

  border: 1px solid variables.$stroke-high;
}
