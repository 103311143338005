@use "scss/variables";
@use "scss/functions";
.wrap {
  padding: functions.get-rem(40px);
  max-width: functions.get-rem(968px);
  background-color: variables.$surface-1;

  .fields {
    width: functions.get-rem(272px);
  }
}

.remove-btn {
  color: variables.$critical-500 !important;
}

.tabs-head {
  display: flex;
  position: relative;
  align-items: center;
  height: functions.get-rem(56px);
  background-color: variables.$surface-1;
  border-bottom: 1px solid variables.$stroke-medium;
  transition: all 1s;
  max-width: functions.get-rem(968px);

  &::before {
    transition: all 0.4s;

    content: "";
    height: 2px;
    background-color: variables.$primary-1;
    position: absolute;
    bottom: 0;
  }

  &.first {
    &::before {
      width: 21%;
      left: 0;
    }
  }

  &.second {
    &::before {
      width: 19%;
      left: 21%;
    }
  }
}

.section-head {
  padding: functions.get-rem(12px) 0;
  border-bottom: 1px solid variables.$stroke-medium;
}

.tab-item {
  height: 100%;
  display: flex;
  cursor: pointer;
  padding: 0 32px;
  align-items: center;
  color: variables.$foreground-low;

  svg {
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);
  }

  &.active {
    color: variables.$primary-1;
  }

  &:nth-child(2) {
    min-width: functions.get-rem(150px);
  }
}
